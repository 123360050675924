export const environment = {
  production: true,
  message: 'Building for Movinglayers PRD environment',
  SERVER_URL: 'https://survey.movinglayers.eu',
  SERVER_PORT: '443',
  BACKEND_URL: 'https://survey-be.movinglayers.eu',
  BACKEND_PORT: '443',
  SOCKET_PORT: '443',
  OTA_VERSION_URL: 'https://survey-prod-ota.movinglayers.eu/version',
  ENVIRONMENT: "prod",
  BUILD_VERSION: 'v0.1.21' //Replaced by CI to something like v0.0.1
}
